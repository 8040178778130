import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import './Styles/Landing.css'
import { isUserAuthorized } from "../userAuth";

/**
 * Temporary workaround
 */

import { useAuth0 } from "@auth0/auth0-react";
import Location from "./LocationComponents/Location";
import { render } from "react-dom";
//const mock_login=process.env.REACT_APP_MOCK_LOGIN == true;

const Events = () => {

  const [accessToken, setAccessToken] = useState([]);
  const [events, setEvents] = useState([]);

  const baseUrl = process.env.REACT_APP_SERVER
  let activeId = 0;
  const {
    user,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      console.log('user is authorized')
      getAccessTokenSilently().then(success => {
        setAccessToken(success);
        fetch(baseUrl + '/event/flagged', {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin': baseUrl,
            'Authorization': 'Bearer ' + success,
          },
        })
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
          })
          .then(data => {
            console.log(data)
            setEvents(data)
          }).catch(e => {
            console.log('catch ', e);
          })

      }).catch(error => console.log('err', error))
    }
  }, [isAuthenticated])

  function renderFlags(flags) {
    return flags.map((flag, index) => {
      return (
        <div>
          User ID: {flag.user}<br />
          Reason: {flag.reason}<br />
          Message: {flag.message}<br />
        </div>
      )
    })
  }

  function displayEvents() {
    return events.map((event, index) => {
      let date = new Date(event.createdAt)
      return (
        <div >
          <Card key={index}>
            <CardBody>
              <CardTitle><b>{event.title} </b></CardTitle>
              <CardSubtitle>ID: {event._id}</CardSubtitle>
              <hr />
              {renderFlags(event.flagsWithReason)}
            </CardBody>
          </Card>
          <br />
        </div>
      )

    })

  }

  return (
    <div>
      {isAuthenticated && isUserAuthorized(user) && (
        <div>
          <h1>Events</h1>
          <hr />
          <div id="Feedback Container"></div>
          {
            displayEvents()
          }
        </div>
      )}
    </div >
  )
};

export default Events;
