import React, { Fragment } from "react";

import Events from "../components/Events";

const EventsView = () => (
  <Fragment>
    <Events />
    <hr />
  </Fragment>
);

export default EventsView;

