import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import NewView from "./views/New";
import AdView from "./views/AdsPage";
import FeedbackView from "./views/Feedback";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import Events from "./views/EventsView";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Autosuggest from "react-autosuggest";
import EventsView from "./views/EventsView";
initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
          <div className="flex-grow-1" style={{ width: "80%", margin:"0 auto", paddingTop:50}}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/New" exact component={NewView} />
              <Route path="/Events" exact component={EventsView} />
              <Route path="/Ads" exact component={AdView} />
              <Route path="/Feedback" exact component={FeedbackView} />
              <Route path="/profile" component={Profile} />
              <Route path="/external-api" component={ExternalApi} />
            </Switch>
          </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
